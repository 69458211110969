import { MockMethod } from 'vite-plugin-mock'

export default [
  {
    url: '/report/configuration/project/list',
    method: 'post',
    response: () => {
      return {
        success: true,
        data: [
          {
            name: 'Compound',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 0,
            id: '36'
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 1,
            id: '36-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 2,
            id: '36-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 3,
            id: '36-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 4,
            id: '36-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 5,
            id: '36-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '36',
            sort: 6,
            id: '36-6'
          },
          {
            name: 'Aave',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 1,
            id: '26'
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 1,
            id: '26-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 2,
            id: '26-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 3,
            id: '26-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 4,
            id: '26-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 5,
            id: '26-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '26',
            sort: 6,
            id: '26-6'
          },
          {
            name: 'Uniswap',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 2,
            id: '11'
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 1,
            id: '11-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 2,
            id: '11-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 3,
            id: '11-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 4,
            id: '11-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 5,
            id: '11-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '11',
            sort: 6,
            id: '11-6'
          },
          {
            name: 'Korbit',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 3,
            id: '114'
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 1,
            id: '114-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 2,
            id: '114-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 3,
            id: '114-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 4,
            id: '114-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 5,
            id: '114-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '114',
            sort: 6,
            id: '114-6'
          },
          {
            name: 'Huobi',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 4,
            id: '117'
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 1,
            id: '117-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 2,
            id: '117-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 3,
            id: '117-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 4,
            id: '117-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 5,
            id: '117-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '117',
            sort: 6,
            id: '117-6'
          },
          {
            name: 'Lido Finance',
            type: 1, // 1 项目 2 配置
            parentId: 0,
            sort: 5,
            id: 24
          },
          {
            name: 'Wallet',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 1,
            id: '24-1'
          },
          {
            name: 'Account',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 2,
            id: '24-2'
          },
          {
            name: 'Event',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 3,
            id: '24-3'
          },
          {
            name: 'JournalEntry',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 4,
            id: '24-4'
          },
          {
            name: 'ReportField',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 5,
            id: '24-5'
          },
          {
            name: 'Currency',
            type: 2, // 1 项目 2 配置
            parentId: '24',
            sort: 6,
            id: '24-6'
          }
        ]
      }
    }
  },
  {
    url: '/report/configuration/list',
    method: 'post',
    response: () => {
      return {
        success: true,
        data: {
          list: [
            {
              id: 36,
              project: 'Compound',
              chain: '以太坊',
              address: '0x0c01089aedc45ab0f43467cceca6b4d3e4170bea',
              addressName: 'krobit 3',
              description:
                'Compound is a non-custodial lending protocol built on Ethereum. Compound was founded by Robert Leshner & Geoffrey Hayes and launched in 2017.'
            },
            {
              id: 22,
              project: 'Ethereum',
              chain: '以太坊',
              address: '0x0c01089aedc45ab0f43467cceca6b4d3e4170bea',
              addressName: 'krobit 3',
              description:
                'Compound is a non-custodial lending protocol built on Ethereum. Compound was founded by Robert Leshner & Geoffrey Hayes and launched in 2017.'
            },
            {
              id: 13,
              project: 'Wrapped BTC',
              chain: '以太坊',
              address: '0x0c01089aedc45ab0f43467cceca6b4d3e4170bea',
              addressName: 'krobit 3',
              description:
                'Compound is a non-custodial lending protocol built on Ethereum. Compound was founded by Robert Leshner & Geoffrey Hayes and launched in 2017.'
            },
            {
              id: 12,
              project: 'Dai Stablecoin',
              chain: '以太坊',
              address: '0x0c01089aedc45ab0f43467cceca6b4d3e4170bea',
              addressName: 'krobit 3',
              description:
                'Compound is a non-custodial lending protocol built on Ethereum. Compound was founded by Robert Leshner & Geoffrey Hayes and launched in 2017.'
            }
          ],
          total: 4
        }
      }
    }
  }
] as MockMethod[]
