// 模拟后端动态生成路由
import { MockMethod } from 'vite-plugin-mock'
import { system, permission } from '@/router/enums'

/**
 * roles：页面级别权限，这里模拟二种 "admin"、"common"
 * admin：管理员角色
 * common：普通角色
 */

const systemRouter = {
  path: '/system',
  meta: {
    icon: 'setting',
    title: 'menus.hssysManagement',
    rank: system,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/system/user/index',
      name: 'User',
      meta: {
        icon: 'flUser',
        title: 'menus.hsUser',
        roles: ['admin']
      }
    },
    {
      path: '/system/role/index',
      name: 'Role',
      meta: {
        icon: 'role',
        title: 'menus.hsRole',
        roles: ['admin']
      }
    },
    {
      path: '/system/dept/index',
      name: 'Dept',
      meta: {
        icon: 'dept',
        title: 'menus.hsDept',
        roles: ['admin']
      }
    }
  ]
}

const permissionRouter = {
  path: '/permission',
  meta: {
    title: 'menus.permission',
    icon: 'lollipop',
    rank: permission,
    showLink: false // 隐藏菜单
  },
  children: [
    {
      path: '/permission/page/index',
      name: 'PermissionPage',
      meta: {
        title: 'menus.permissionPage',
        roles: ['admin', 'common']
      }
    },
    {
      path: '/permission/button/index',
      name: 'PermissionButton',
      meta: {
        title: 'menus.permissionButton',
        roles: ['admin', 'common'],
        auths: ['btn_add', 'btn_edit', 'btn_delete']
      }
    }
  ]
}

export default [
  {
    url: '/getAsyncRoutes',
    method: 'get',
    response: () => {
      console.log({
        success: true,
        data: [systemRouter, permissionRouter, tabsRouter]
      })
      return {
        success: true,
        data: [systemRouter, permissionRouter]
      }
    }
  }
] as MockMethod[]
